import React from "react";
import SwicthBtn from "@components/SwicthBtn";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const AnnualList = ({ items }) => {
  return (
    <div className="approval-modal-items-body">
      <div className="approval-modal-items-title-box">
        <div className="approval-modal-items-title">Detailed Schedule</div>
      </div>
      <div className="approval-modal-items-box">
        {items.map((c, i) => {
          const {
            id,
            annual_leave_policy_id,
            user_id,
            date_year,
            date_month,
            date_day,
            morning_date_half,
            afternoon_date_half,
            cancel_boolean,
            approve_use,
            approve_status,
          } = c;

          const date = `${date_year}-${date_month}-${date_day}`;

          const week = dayjs(date).format("ddd");

          return (
            <div
              key={id}
              className="approval-modal-items-work-list-card align-center"
            >
              <div className="approval-modal-items-default-text">{`${
                i + 1
              }. ${date} (${week})`}</div>
              <div className="approval-modal-items-work-list-range-box">
                <div style={{ width: 180 }}>
                  <SwicthBtn
                    checked={morning_date_half === 1 ? true : false}
                    label={`Morning ${morning_date_half ? "ON" : "OFF"}`}
                    // onClick={() => handleHalfUse(c, "morning")}
                  />
                </div>
                <div style={{ width: 180 }}>
                  <SwicthBtn
                    checked={afternoon_date_half === 1 ? true : false}
                    label={`Afternoon ${afternoon_date_half ? "ON" : "OFF"}`}
                    // onClick={() => handleHalfUse(c, "afternoon")}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnnualList;
