import React from "react";
import MonthPicker from "@components/MonthPicker";
import ApprovalCard from "./ApprovalCard";
import { WorkTypeIcon, AnnualLeaveIcon } from "./Icons";

const ApprovalMenu = ({
  dateValue,
  handleDateValue,
  approvalList = [],
  modalType,
  approval_list_refresh,
}) => {
  return (
    <div className="app-manage-tab-menu-body">
      <div className="app-manage-tab-menu-picker-area">
        <MonthPicker value={dateValue} onChange={handleDateValue} />
      </div>
      {approvalList.map((c) => {
        const {
          id,
          approval_date,
          policy_table_name,
          username,
          org_setting_name,
          lv_name,
          fn_name,
          approved_status,
          policy_name,
          status_color,
          need_approval,
        } = c;

        const icon = createCardIcon(policy_table_name);

        const sub_title = `${policy_name}・Department:${org_setting_name}・Job Grade:${lv_name}・Job Position:${fn_name}`;

        return (
          <ApprovalCard
            key={id}
            id={id}
            icon={icon}
            title={username}
            sub_title={sub_title}
            title_date={approval_date}
            status_name={approved_status}
            status_color={status_color}
            modal_type={modalType}
            approval_list_refresh={approval_list_refresh}
            need_approval={need_approval}
          />
        );
      })}
    </div>
  );
};

export default ApprovalMenu;

const createCardIcon = (policy_name) => {
  if (policy_name === "work_type_policy") {
    return <WorkTypeIcon />;
  }

  if (
    policy_name === "annual_leave_policy" ||
    policy_name === "dayoff_policy"
  ) {
    return <AnnualLeaveIcon />;
  }

  return undefined;
};
