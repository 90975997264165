import React from "react";

const InitialBudgetPage = () => {
  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Initial Budget</div>
        <div className="work-policy-setting-page-btn-box"></div>
      </div>
      <div className="company-setting-contents-body"></div>
    </div>
  );
};

export default InitialBudgetPage;
