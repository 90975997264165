import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { ApprovalModalStatus } from "@/atoms/modalStatus";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { Tabs, Tab, Box } from "@mui/material";
import ApprovalMenu from "./ApprovalMenu";
import ApprovalModal from "./Modal/ApprovalModal";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import "./approvalManagement.scss";

const ApprovalManagementBody = () => {
  const { open } = useRecoilValue(ApprovalModalStatus);
  const [tabValue, setTabValue] = useState(0);
  const [dateValue, setDateValue] = useState(dayjs());

  const approval_list_query_key = [
    "approval_list",
    tabValue,
    dateValue.format("YYYY-MM"),
  ];

  const query_approval_list = useQuery(approval_list_query_key, () => {
    return query_fetch_data(tabValue, dateValue);
  });

  const [approval_list_refresh] = useRefreshReactQuery(approval_list_query_key);

  const handleDateValue = (e) => {
    setDateValue(e);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { data = {}, isLoading } = query_approval_list;

  const { list = [] } = data;

  if (isLoading) {
    return null;
  }

  return (
    <div className="app-manage-body">
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Approval"
              {...a11yProps(0)}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Carbon Copy"
              {...a11yProps(1)}
              sx={{ textTransform: "none" }}
            />

            <Tab
              label="Draft"
              {...a11yProps(2)}
              sx={{ textTransform: "none" }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <ApprovalMenu
            dateValue={dateValue}
            approvalList={list}
            handleDateValue={handleDateValue}
            modalType="approval"
            approval_list_refresh={approval_list_refresh}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <ApprovalMenu
            dateValue={dateValue}
            approvalList={list}
            handleDateValue={handleDateValue}
            modalType="carbon_copy"
            approval_list_refresh={approval_list_refresh}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <ApprovalMenu
            dateValue={dateValue}
            approvalList={list}
            handleDateValue={handleDateValue}
            modalType="draft"
            approval_list_refresh={approval_list_refresh}
          />
        </CustomTabPanel>
      </Box>
      {open && <ApprovalModal />}
    </div>
  );
};

export default ApprovalManagementBody;

const query_fetch_data = async (tabValue, dateValue) => {
  const result = { list: [] };

  try {
    const findtabName = tabValueList.find((c) => c.value === tabValue);

    const tabname = findtabName?.name ?? "";

    if (tabname === "") {
      return result;
    }

    const year = dateValue.format("YYYY");
    const month = dateValue.format("MM");

    const request_approval_list = await userPermAxios.get(
      `/approval/${tabname}/get_approval_list?year=${year}&month=${month}`
    );

    const { success, data } = request_approval_list;

    if (success) {
      result.list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const tabValueList = [
  { value: 0, name: "approval" },
  { value: 1, name: "carbon_copy" },
  { value: 2, name: "draft" },
];

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "calc(100% - 49px)" }}
    >
      {value === index && children}
    </div>
  );
}
