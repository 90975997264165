import React, { useState, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { MaterialModalStatus } from "@atoms/modalStatus";
import { MaterialCategory } from "@/atoms/material";
import { Modal, Box, Button } from "@mui/material";
import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";

const MaterialAddModal = () => {
  const [materialModalStatus, setMaterialModalStatus] =
    useRecoilState(MaterialModalStatus);

  const { open, table_name, query_key } = materialModalStatus;

  const [refresh] = useRefreshReactQuery(query_key);

  const materialCategory = useRecoilValue(MaterialCategory);

  const [matDescription, setMatDescription] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [matUom, setMatUom] = useState("");
  const [matSize, setMatSize] = useState("");
  const [matItem, setMatItem] = useState("");
  const [matType, setMatType] = useState("");
  const [matMaterial, setMatMaterial] = useState("");

  const [dubleClick, setDubleClick] = useState(true);

  const button_disabled = useMemo(() => {
    const textFieldInspection = [
      matDescription,
      matUom,
      matSize,
      matMaterial,
      categoryValue,
      matItem,
      matType,
    ].every((c) => {
      const textReplaceAll = c.replaceAll(" ", "");

      return textReplaceAll.length > 0;
    });

    return !textFieldInspection;
  }, [
    matDescription,
    matUom,
    matSize,
    matMaterial,
    categoryValue,
    matItem,
    matType,
  ]);

  const closeFn = () => {
    return setMaterialModalStatus({
      open: false,
      table_name: null,
      parent_id: null,
      query_key: null,
    });
  };

  const createMaterialMaster = async () => {
    if (!dubleClick) return;

    setDubleClick(false);
    try {
      const requestCreateMaterialItem = await userPermAxios.post(
        `/${table_name}/new-material`,
        {
          mat_description: matDescription,
          mat_uom: matUom,
          mat_size: matSize,
          mat_material: matMaterial,
          mat_cat: categoryValue,
          mat_item: matItem,
          mat_type: matType,
        }
      );

      const { success } = requestCreateMaterialItem;

      if (success) {
        refresh();
      }
    } catch (err) {
      const response = err?.response?.data;
      if (response) {
        const { message } = response;
        create_alert_fn("warning", message, () => {});
      }
    } finally {
      setDubleClick(true);
      closeFn();
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeFn}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="attachment-modal-body">
          <div className="attachment-modal-material-add-body">
            <LabelInput
              label="Description"
              value={matDescription}
              setValue={setMatDescription}
            />
            <LabelSelect
              label="Category"
              value={categoryValue}
              setValue={setCategoryValue}
              list={materialCategory.map((c) => ({ ...c, value: c.name }))}
            />

            <LabelInput label="Unit" value={matUom} setValue={setMatUom} />
            <LabelInput label="Size" value={matSize} setValue={setMatSize} />
            <LabelInput label="Item" value={matItem} setValue={setMatItem} />
            <LabelInput label="Type" value={matType} setValue={setMatType} />
            <LabelInput
              label="Material"
              value={matMaterial}
              setValue={setMatMaterial}
            />
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="500"
              height="1"
              viewBox="0 0 640 1"
              fill="none"
            >
              <path d="M0 0H640V1H0V0Z" fill="#E4E7EC" />
            </svg>
          </div>
          <div className="attachment-modal-control-box">
            <Button
              onClick={createMaterialMaster}
              disabled={button_disabled}
              className="attachment-modal-submit-btn"
            >
              Submit
            </Button>
            <Button
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              onClick={closeFn}
              className="attachment-modal-cancle-btn"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default MaterialAddModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "16px",
  border: "1px solid var(--Gray-200, #E4E7EC)",
  background: "var(--White, #FFF)",
  boxShadow:
    "0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
};
