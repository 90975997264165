import React, { useState } from "react";
import { useQuery } from "react-query";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import {
  SalaryAdditionalModalStatus,
  SalaryDeductionModalStatus,
} from "@/atoms/modalStatus";
import MonthPicker from "@components/MonthPicker";
import Btn from "@components/CustomIconBtn";
import { GenerateIcon, ConfirmSalaryIcon, TracshIcon } from "./Icon";
import AdditionalModal from "./AdditionalModal";
import DeductionModal from "./DeductionModal";
import DailyWageModal from "./DailyWageModal";
import ImportModal from "./ImportModal";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import { downloadBase64File } from "@/utils/downloadFns";
import { createDailyWageManagementColums } from "./Columns";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import "../payrollManagement/payrollManagement.scss";

const DailyWageManagement = () => {
  const apiRef = useGridApiRef();
  const { open: additionalModalOpen } = useRecoilValue(
    SalaryAdditionalModalStatus
  );
  const { open: deductionModalOpen } = useRecoilValue(
    SalaryDeductionModalStatus
  );

  const [baseDateValue, setBalseDateValue] = useState(dayjs());
  const [selectionModel, setSelectionModel] = useState([]);
  const [dailyModalToggle, setDailyModalToggle] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);
  const [importModalToggle, setImportModalToggle] = useState(false);

  const salary_list_query_key = [
    "user_salary_list",
    baseDateValue.format("YYYY"),
    baseDateValue.format("MM"),
  ];

  const query_users_salary_list = useQuery(salary_list_query_key, () => {
    return query_fetch_data(
      baseDateValue.format("YYYY"),
      baseDateValue.format("MM")
    );
  });

  const { data = {}, isLoading } = query_users_salary_list;

  const { list: salary_list = [] } = data;

  const [refresh] = useRefreshReactQuery(salary_list_query_key);

  const handleSelectionChange = (selection) => {
    setSelectionModel(selection);
  };

  const handleDailyWageModalToggle = () => {
    setDailyModalToggle((prev) => !prev);
  };

  const handleImportModalToggle = () => {
    setImportModalToggle((prev) => !prev);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="prm-body">
      {importModalToggle && (
        <ImportModal
          open={importModalToggle}
          onClose={handleImportModalToggle}
          downloadFileName="daily_wage_templete"
          downloadFilePath="daily_wage_templete"
          uploadData={{
            year: baseDateValue.format("YYYY"),
            month: baseDateValue.format("MM"),
          }}
          uploadPath="/payroll/import_daily_wage"
          refresh={refresh}
        />
      )}
      <div className="prm-btn-body">
        <div className="prm-btn-box">
          <MonthPicker
            value={baseDateValue}
            onChange={(e) => setBalseDateValue(e)}
          />
          <div className="prm-btn-vertical-line" />
          <Btn
            icon={<GenerateIcon />}
            label="Generate"
            width={139}
            onClick={handleDailyWageModalToggle}
          />
          <Btn
            icon={<img src="/icons/excel_icon.png" alt="export_icon" />}
            label="Import"
            width={139}
            onClick={handleImportModalToggle}
          />
          <div className="prm-btn-vertical-line" />
          <Btn
            icon={<TracshIcon />}
            width={40}
            disabled={selectionModel.length === 0}
            onClick={() =>
              confirmCreaeteSalaryList(
                dubleClick,
                setDubleClick,
                refresh,
                "delete",
                baseDateValue.format("YYYY"),
                baseDateValue.format("MM"),
                selectionModel,
                setSelectionModel
              )
            }
          />
        </div>
        <div className="prm-btn-box">
          <Btn
            icon={<img src="/icons/excel_icon.png" alt="export_icon" />}
            label="Export"
            width={139}
            disabled={salary_list.length === 0}
            onClick={() =>
              confirmCreaeteSalaryList(
                dubleClick,
                setDubleClick,
                refresh,
                "download",
                baseDateValue.format("YYYY"),
                baseDateValue.format("MM"),
                selectionModel,
                setSelectionModel
              )
            }
          />
          <div className="prm-btn-vertical-line" />
          <Btn
            icon={<ConfirmSalaryIcon />}
            label="Confirm Daily Wage"
            width={188}
            onClick={() =>
              confirmCreaeteSalaryList(
                dubleClick,
                setDubleClick,
                refresh,
                "confirm",
                baseDateValue.format("YYYY"),
                baseDateValue.format("MM"),
                selectionModel,
                setSelectionModel
              )
            }
            disabled={selectionModel.length === 0}
          />
        </div>
      </div>
      <div className="prm-table-body">
        <DataGridPro
          rows={salary_list}
          columns={createDailyWageManagementColums(
            baseDateValue.format("YYYY"),
            baseDateValue.format("MM")
          )}
          checkboxSelection={true}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectionModel}
          hideFooter
          disableRowSelectionOnClick
          // {...addRowRef}
          apiRef={apiRef}
          rowHeight={40}
          columnHeaderHeight={40}
          sx={{
            ...materialTableStyle,
            ...TableScrollbarStyle,
            "& .already-selection-row": {
              backgroundColor: "#F9FAFB",
              color: "#D0D5DD",
            },
          }}
          getRowClassName={(params) => {
            const { row = {} } = params;

            const { payment_confirm = 1 } = row;

            if (payment_confirm === 1) {
              return "already-selection-row";
            }
            return "";
          }}
          disableColumnPinning
          disableColumnSelector
          disableDensitySelector
          isRowSelectable={(params) => {
            const { row = {} } = params;
            const { payment_confirm = 1 } = row;

            if (payment_confirm === 1) {
              return false;
            }

            return true;
          }}
        />
      </div>
      {additionalModalOpen && <AdditionalModal refresh={refresh} />}
      {deductionModalOpen && <DeductionModal refresh={refresh} />}
      {dailyModalToggle && (
        <DailyWageModal
          open={dailyModalToggle}
          onClose={handleDailyWageModalToggle}
          year={baseDateValue.format("YYYY")}
          month={baseDateValue.format("MM")}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default DailyWageManagement;

const query_fetch_data = async (year, month) => {
  let result = { list: [] };

  try {
    const requestSalaryList = await userPermAxios.get(
      `/payroll/get_daily_wage_list?year=${year}&month=${month}`
    );
    const { success, data } = requestSalaryList;
    if (success) {
      result.list = data?.list ?? [];
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const confirmSalaryList = async (
  setDubleClick,
  reFreshFn = () => {},
  ids,
  setSelectionModel
) => {
  setDubleClick(false);

  let msgStatus = {
    status: "success",
    message: "",
  };
  try {
    const requestConfirmSalaryList = await userPermAxios.post(
      `/payroll/confirm_daily_wage_list`,
      {
        ids,
      }
    );

    const { success, message } = requestConfirmSalaryList;

    if (success) {
      msgStatus.status = "success";
    } else {
      msgStatus.status = "warning";
    }

    msgStatus.message = message;
  } catch (err) {
    msgStatus.status = "warning";
    if (err.response && err?.response?.data?.message) {
      msgStatus.message = err?.response?.data?.message;
    }
  } finally {
    setDubleClick(true);

    create_alert_fn(msgStatus.status, msgStatus.message);

    if (msgStatus.status === "success") {
      setSelectionModel([]);
      reFreshFn();
    }
  }
};

const deleteSalaryList = async (
  setDubleClick,
  reFreshFn = () => {},
  ids,
  setSelectionModel
) => {
  setDubleClick(false);

  let msgStatus = {
    status: "success",
    message: "",
  };
  try {
    const requestDeleteSalaryList = await userPermAxios.post(
      `/payroll/delete_daily_wage_list`,
      {
        ids,
      }
    );

    const { success, message } = requestDeleteSalaryList;

    if (success) {
      msgStatus.status = "success";
    } else {
      msgStatus.status = "warning";
    }

    msgStatus.message = message;
  } catch (err) {
    msgStatus.status = "warning";
    if (err.response && err?.response?.data?.message) {
      msgStatus.message = err?.response?.data?.message;
    }
  } finally {
    setDubleClick(true);

    create_alert_fn(msgStatus.status, msgStatus.message);

    if (msgStatus.status === "success") {
      setSelectionModel([]);
      reFreshFn();
    }
  }
};

const downloadSalaryList = async (
  setDubleClick,
  reFreshFn = () => {},
  year,
  month
) => {
  setDubleClick(false);

  let msgStatus = {
    status: "success",
    message: "",
  };
  try {
    const requestDownloadSalaryList = await userPermAxios.get(
      `/payroll/export_daily_wage?year=${year}&month=${month}`
    );

    const { success, message, data } = requestDownloadSalaryList;

    if (success) {
      msgStatus.status = "success";
      downloadBase64File(data, `${year}_${month} daily wage list.xlsx`);
    } else {
      msgStatus.status = "warning";
    }

    msgStatus.message = message;
  } catch (err) {
    msgStatus.status = "warning";
    if (err.response && err?.response?.data?.message) {
      msgStatus.message = err?.response?.data?.message;
    }
  } finally {
    setDubleClick(true);

    create_alert_fn(msgStatus.status, msgStatus.message);

    if (msgStatus.status === "success") {
      reFreshFn();
    }
  }
};

const confirmCreaeteSalaryList = (
  dubleClick,
  setDubleClick,
  reFreshFn = () => {},
  type = "create",
  year,
  month,
  ids,
  setSelectionModel
) => {
  if (!dubleClick) {
    return;
  }

  let msg;

  if (type === "confirm") {
    msg = "Are you sure you want to confirm this daily wage list?";
  }

  if (type === "delete") {
    msg = "Are you sure you want to delete this daily wage list?";
  }

  if (type === "download") {
    msg = "Are you sure you want to download this daily wage list?";
  }

  return create_alert_fn(
    "info",
    msg,
    () => {
      if (type === "confirm") {
        confirmSalaryList(setDubleClick, reFreshFn, ids, setSelectionModel);
      }

      if (type === "delete") {
        deleteSalaryList(setDubleClick, reFreshFn, ids, setSelectionModel);
      }

      if (type === "download") {
        downloadSalaryList(setDubleClick, reFreshFn, year, month);
      }
    },
    true
  );
};
