import React, { useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { RechargeConditionStatus } from "@/atoms/modalStatus";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import ModalBody from "@components/ModalBody";
import RechargeConditionModal from "./RechargeConditionModal";
import { createRechargeColumn } from "./Column";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const RechargeConditionSelectModal = ({
  title = "",
  open = true,
  onClose = () => {},
  tableRow = [],
  initialSelectList = [],
  newcomerStartOptions,
  underDecimalOptions,
  onConfirm = () => {},
  disabled = false,
  refresh,
}) => {
  const [rechargeConditionStatus, setRechargeConditionStatus] = useRecoilState(
    RechargeConditionStatus
  );

  const [selectModel, setSelectModel] = useState(initialSelectList);

  const handleSelectionChange = (selection) => {
    if (!disabled) {
      setSelectModel(selection);
    }
  };

  const closeRechargeConditionModal = useResetRecoilState(
    RechargeConditionStatus
  );

  const openRechargeConditionModal = () => {
    setRechargeConditionStatus((prev) => {
      return {
        ...prev,
        open: true,
        action_type: "create",
        title: "Recharge Condition Setting",
        onClose: closeRechargeConditionModal,
        initialSelectList: initialSelectList,
        newcomerStartOptionsList: newcomerStartOptions,
        underDecimalOptionsList: underDecimalOptions,
        reFresh: refresh,
      };
    });
  };

  return (
    <ModalBody
      title={title}
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={!rechargeConditionStatus.open}
    >
      <div
        className="work-policy-modal-contents-body"
        style={{ maxHeight: "500px", boxSizing: "border-box" }}
      >
        {rechargeConditionStatus.open && <RechargeConditionModal />}
        <div
          className="select-data-table-table-box"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <DataGridPro
            rows={tableRow}
            columns={createRechargeColumn(
              closeRechargeConditionModal,
              refresh,
              newcomerStartOptions,
              underDecimalOptions
            )}
            checkboxSelection={true}
            rowSelectionModel={selectModel}
            disableMultipleRowSelection={true}
            onRowSelectionModelChange={handleSelectionChange}
            rowHeight={28}
            columnHeaderHeight={28}
            getRowClassName={(params) => {
              const { row = {} } = params;

              const { material_use } = row;

              if (material_use === 1) {
                return "already-selection-row";
              }
              return "";
            }}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
              "& .already-selection-row": {
                backgroundColor: "#F9FAFB",
                color: "#D0D5DD",
              },
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
          />
        </div>
      </div>
      {!disabled && (
        <div
          className="work-policy-modal-btn-box"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={openRechargeConditionModal}
            className="work-policy-modal-cancel-btn"
          >
            Create
          </Button>
          <div
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Button
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              onClick={onClose}
              className="work-policy-modal-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onConfirm(selectModel);
              }}
              className="work-policy-modal-update-btn"
              disabled={disabled}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </ModalBody>
  );
};

export default RechargeConditionSelectModal;
