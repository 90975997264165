import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { PayrollDeductionPolicyModalStatus } from "@/atoms/modalStatus";
import PolicyModalBody from "@components/PolicyModalBody";
import LabelSelect from "@components/LabelSelect";
import LabelInput from "@components/LabelInput";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";

import "../dayOffSettingModals/dayOffSettingModals.scss";

const PayrolldeductionModal = ({ refresh = () => {} }) => {
  const {
    open,
    onClose,
    title,
    type,
    id,
    origin_id,
    initialPolicyName,
    initialDeductionMethod,
    initialDeductionPercentage,
    initialDeductionPrice,
    initialNote,
  } = useRecoilValue(PayrollDeductionPolicyModalStatus);

  const button_disabled = type === "view" || type === "" ? true : false;

  const [policyName, setPolicyName] = useState(initialPolicyName);
  const [deductionMethod, setDeductionMethod] = useState(
    initialDeductionMethod
  );
  const [deductionPercentage, setDeductionPercentage] = useState(
    initialDeductionPercentage
  );
  const [deductionPrice, setDeductionPrice] = useState(initialDeductionPrice);
  const [note, setNote] = useState(initialNote);
  const [dubleClick, setDubleClick] = useState(true);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*\.?\d*$/;

    if (value === "") {
      setValue(0);
      return;
    }

    if (isValidNumber.test(value)) {
      setValue(value);
    }
  };

  const saveBtnDisabled = useMemo(() => {
    if (type !== "create" && type !== "update") {
      return true;
    }

    if (policyName === "") {
      return true;
    }

    if (deductionMethod === "percent") {
      if (Number(deductionPercentage) <= 0) {
        return true;
      }
    }

    if (deductionMethod === "cost") {
      if (Number(deductionPrice) <= 0) {
        return true;
      }
    }

    return false;
  }, [type, policyName, deductionMethod, deductionPercentage, deductionPrice]);

  const createDeductionPolicy = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const submitData = {
        policyName,
        deductionMethod,
        deductionPercentage: Number(deductionPercentage),
        deductionPrice: Number(deductionPrice),
        note,
      };

      if (type === "update") {
        submitData.id = id;
        submitData.origin_id = origin_id;
      }

      const requestCreatePolicy = await userPermAxios.post(
        `/payroll/${type}_deduction_policy`,
        {
          ...submitData,
        }
      );

      const { success, message } = requestCreatePolicy;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const saveDeductionPolicy = () => {
    let message;

    if (type === "create") {
      message = "Do you want to create a new payroll deduction policy?";
    }

    if (type === "update") {
      message = "Do you want to update a payroll deduction policy?";
    }

    return create_alert_fn(
      "info",
      message,
      async () => {
        await createDeductionPolicy();
      },
      true
    );
  };

  return (
    <PolicyModalBody
      policyName={policyName}
      setPolicyName={setPolicyName}
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={true}
      width={792}
      button_disabled={button_disabled}
      save_btn_disabled={saveBtnDisabled}
      dubleClick={dubleClick}
      saveFn={saveDeductionPolicy}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Approval required
            </div>
            <LabelSelect
              width={167}
              value={deductionMethod}
              setValue={setDeductionMethod}
              list={[
                { name: "Price", value: "cost" },
                { name: "Percent", value: "percent" },
              ]}
            />
          </div>
          <div
            className="work-policy-modal-swicth-btn-box"
            style={{ height: 44 }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Deduction Percent(%)
            </div>
            <NumberInput
              value={deductionPercentage}
              setValue={setDeductionPercentage}
              onChange={handleNumberInputValue}
              disabled={deductionMethod === "cost"}
            />
          </div>
          <div
            className="work-policy-modal-swicth-btn-box"
            style={{ height: 44 }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Deduction Price
            </div>
            <NumberInput
              value={deductionPrice}
              setValue={setDeductionPrice}
              onChange={handleNumberInputValue}
              disabled={deductionMethod === "percent"}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">Note</div>
            <LabelInput value={note} setValue={setNote} />
          </div>
        </div>
      </div>
    </PolicyModalBody>
  );
};

export default PayrolldeductionModal;

const NumberInput = ({ value, setValue, onChange, disabled = false }) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{ width: 167, boxSizing: "border-box" }}
    />
  );
};
