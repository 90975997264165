export const WorkTypeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M3 13.988C3 13.3932 3.48215 12.9111 4.07692 12.9111H36.9231C37.5178 12.9111 38 13.3932 38 13.988V33.3726C38 33.9674 37.5178 34.4495 36.9231 34.4495H4.07692C3.48215 34.4495 3 33.9674 3 33.3726V13.988Z"
        fill="#E0BE87"
      />
      <path
        d="M31.8424 12.9111H36.9231C37.5178 12.9111 38 13.3932 38 13.988V33.3726C38 33.9674 37.5178 34.4495 36.9231 34.4495H31.8424V12.9111Z"
        fill="#D4B178"
      />
      <path
        d="M3 30.7515H38V33.3726C38 33.9674 37.5178 34.4495 36.9231 34.4495H4.07692C3.48215 34.4495 3 33.9674 3 33.3726V30.7515Z"
        fill="#D4B178"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1073 12.9111H4.07692C3.48215 12.9111 3 13.3932 3 13.988V19.6418H38V13.988C38 13.3932 37.5178 12.9111 36.9231 12.9111H27.8937C27.6946 11.969 27.272 10.9104 26.5139 9.9703C25.3403 8.51493 23.4196 7.41602 20.5005 7.41602C17.5815 7.41602 15.6608 8.51493 14.4872 9.9703C13.7291 10.9104 13.3065 11.969 13.1073 12.9111ZM14.7691 12.9111H26.2319C26.0567 12.2782 25.7486 11.5947 25.2564 10.9843C24.4108 9.93569 22.9661 9.0314 20.5005 9.0314C18.035 9.0314 16.5903 9.93569 15.7446 10.9843C15.2525 11.5947 14.9443 12.2782 14.7691 12.9111Z"
        fill="#B88E4B"
      />
      <path
        d="M31.8424 12.9111H36.9231C37.5178 12.9111 38 13.3932 38 13.988V19.6418H31.8424V12.9111Z"
        fill="#B88E4B"
      />
      <path
        d="M17.1346 18.8341C17.1346 18.5368 17.3757 18.2957 17.6731 18.2957H23.3269C23.6243 18.2957 23.8654 18.5368 23.8654 18.8341V21.2572C23.8654 21.5546 23.6243 21.7957 23.3269 21.7957H17.6731C17.3757 21.7957 17.1346 21.5546 17.1346 21.2572V18.8341Z"
        fill="#E8E8E8"
      />
      <path
        d="M23.3269 18.2957H23.2396V20.8973H17.1346V21.2572C17.1346 21.5546 17.3757 21.7957 17.6731 21.7957H23.3269C23.6243 21.7957 23.8654 21.5546 23.8654 21.2572V18.8341C23.8654 18.5368 23.6243 18.2957 23.3269 18.2957Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};
export const AnnualLeaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M3.00098 10.8608C3.00098 10.0937 3.62278 9.47193 4.38982 9.47193H36.6099C37.377 9.47193 37.9988 10.0937 37.9988 10.8608V34.2477C37.9988 35.0147 37.377 35.6365 36.6099 35.6365H4.38982C3.62278 35.6365 3.00098 35.0147 3.00098 34.2477V10.8608Z"
        fill="#FCFCFD"
      />
      <path
        d="M3.00098 9.80626C3.00098 9.03922 3.62278 8.41741 4.38982 8.41741H36.611C37.3781 8.41741 37.9999 9.03922 37.9999 9.80626V13.9782H3.00098V9.80626Z"
        fill="#FDA29B"
      />
      <path
        d="M36.611 8.41741H36.4211V12.3754H3.00208L3.00098 13.9782H37.9999L38.001 12.3754L37.9999 9.80626C37.9999 9.03922 37.3781 8.41741 36.611 8.41741Z"
        fill="#F18181"
      />
      <path
        d="M8.00027 6.78796C8.00027 6.48114 8.249 6.23242 8.55581 6.23242H10.778C11.0848 6.23242 11.3335 6.48114 11.3335 6.78796V12.6211C11.3335 12.9279 11.0848 13.1766 10.778 13.1766H8.55581C8.249 13.1766 8.00027 12.9279 8.00027 12.6211V6.78796Z"
        fill="#F2F4F7"
      />
      <path
        d="M10.778 6.23242H10.5365V12.3788H8.00027V12.6211C8.00027 12.9279 8.249 13.1766 8.55581 13.1766H10.778C11.0848 13.1766 11.3335 12.9279 11.3335 12.6211V6.78796C11.3335 6.48114 11.0848 6.23242 10.778 6.23242Z"
        fill="#D5DAE0"
      />
      <path
        d="M29.6662 6.78796C29.6662 6.48114 29.915 6.23242 30.2218 6.23242H32.4439C32.7508 6.23242 32.9995 6.48114 32.9995 6.78796V12.6211C32.9995 12.9279 32.7508 13.1766 32.4439 13.1766H30.2218C29.915 13.1766 29.6662 12.9279 29.6662 12.6211V6.78796Z"
        fill="#F2F4F7"
      />
      <path
        d="M32.4439 6.23242H32.2025V12.3788H29.6662V12.6211C29.6662 12.9279 29.915 13.1766 30.2218 13.1766H32.4439C32.7508 13.1766 32.9995 12.9279 32.9995 12.6211V6.78796C32.9995 6.48114 32.7508 6.23242 32.4439 6.23242Z"
        fill="#D5DAE0"
      />
      <g filter="url(#filter0_i_2444_95862)">
        <path
          d="M28.3337 18.5202C28.8261 19.2549 28.6135 20.1135 28.2886 20.7403C27.9534 21.387 27.4124 21.9764 26.9046 22.3478L23.6069 24.7602L24.5318 31.2765C24.6227 31.9169 24.3595 32.556 23.8519 32.9273L22.6172 33.8306C22.4858 33.9266 22.3207 33.9574 22.1657 33.9149C22.0106 33.8723 21.8814 33.7606 21.8123 33.6095L19.1872 27.8695L16.7962 28.8343L16.5863 31.0833C16.548 31.3102 16.3827 31.4907 16.1673 31.5406C15.952 31.5904 15.7291 31.4999 15.6026 31.3111L12.4746 26.644C12.348 26.4553 12.3428 26.2055 12.4613 26.0111C12.5799 25.8168 12.7988 25.7161 13.016 25.7562L15.0182 26.125L16.7962 24.302L12.5311 19.7617C12.4188 19.6422 12.3626 19.4771 12.3773 19.3103C12.392 19.1436 12.4762 18.992 12.6076 18.8959L13.8423 17.9927C14.3499 17.6214 15.0148 17.5815 15.5597 17.8898L21.1045 21.0265L24.4022 18.6142C24.91 18.2427 25.6251 17.9131 26.3215 17.8053C26.9963 17.7007 27.8414 17.7856 28.3337 18.5202Z"
          fill="#F2F4F7"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2444_95862"
          x="12.375"
          y="17.6836"
          width="16.25"
          height="16.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.555538" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.557731 0 0 0 0 0.553937 0 0 0 0 0.553937 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2444_95862"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const AttachmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.0936 6.00043C11.0931 5.94613 11.104 5.89226 11.1258 5.84199C11.1475 5.79172 11.1797 5.74605 11.2204 5.70765C11.261 5.66925 11.3094 5.63889 11.3627 5.61835C11.4159 5.5978 11.4729 5.58749 11.5305 5.588C11.6474 5.58895 11.7593 5.63324 11.842 5.71133C11.9248 5.78943 11.9717 5.89507 11.9727 6.00551L12 11.1336C11.8638 12.1556 11.478 12.9487 10.8369 13.4957C10.0287 14.1843 9.1371 14.4728 8.1483 14.4986C7.14023 14.5248 6.10573 14.1809 5.25806 13.3806C4.60753 12.7664 4.22401 12.0473 4.11514 11.1907L4 3.88836C4.06944 3.22636 4.34498 2.67772 4.82213 2.26191C5.42876 1.73484 6.05018 1.50346 6.69086 1.50007C7.44041 1.49542 8.12903 1.70904 8.74418 2.28983C9.23342 2.75175 9.51613 3.33761 9.5914 4.06687L9.66174 10.4648C9.67966 10.9894 9.58692 11.3984 9.35932 11.6911C9.20816 11.8872 9.0085 12.0454 8.77774 12.1519C8.54698 12.2585 8.29213 12.3101 8.03539 12.3024C7.5448 12.2893 7.12814 12.0887 6.806 11.7169C6.58647 11.4631 6.46147 11.1564 6.42966 10.7795L6.35439 4.88792C6.35355 4.83369 6.36416 4.77985 6.38559 4.72953C6.40702 4.67921 6.43885 4.63342 6.47922 4.59484C6.51958 4.55625 6.56769 4.52564 6.62071 4.50479C6.67374 4.48393 6.73064 4.47326 6.78808 4.47338C6.90506 4.47366 7.01724 4.51731 7.10045 4.59492C7.18367 4.67254 7.23126 4.77792 7.23297 4.88835L7.3069 10.7508C7.32258 10.9348 7.38172 11.0795 7.48342 11.1966C7.64471 11.3828 7.81989 11.4669 8.04928 11.4729C8.16597 11.4766 8.28184 11.4531 8.38658 11.4044C8.49133 11.3557 8.58169 11.2833 8.64964 11.1937C8.74194 11.0752 8.79525 10.8379 8.78315 10.472L8.71416 4.10156C8.66129 3.59734 8.4664 3.19336 8.12634 2.8723C7.69892 2.46876 7.23835 2.32578 6.70699 2.32917C6.2836 2.33128 5.86918 2.48568 5.42115 2.87484C5.10439 3.15106 4.92294 3.51273 4.87634 3.93236L4.9897 11.1433C5.07124 11.766 5.3629 12.3138 5.875 12.7973C6.53898 13.4242 7.33602 13.6886 8.11335 13.6687C8.90726 13.6484 9.60081 13.4233 10.2428 12.8764C10.7177 12.472 11.0157 11.8591 11.1259 11.0731L11.0936 6.00043Z"
        fill="#0080DB"
      />
    </svg>
  );
};
