import React, { useMemo, useState } from "react";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { FileUploader } from "react-drag-drop-files";

import "./excelImportBtn.scss";
import { userPermAxios, defaultAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import { downloadBase64File, downloadBlobFile } from "@/utils/downloadFns";

const ImportModal = ({ open, onClose = () => {} }) => {
  const [file, setFile] = useState(null);
  const [dubleClick, setDubleClick] = useState(true);

  const handleChange = (file) => {
    setFile(file);
  };

  const downloadTempleteFile = async () => {
    try {
      const requestTempleteFile = await defaultAxios.get(
        "/download/material_master_templete",
        {
          withCredentials: true,
          responseType: "blob",
        }
      );

      downloadBlobFile(requestTempleteFile, "material_import_temp.xlsx");
    } catch (err) {
      create_alert_fn(
        "warning",
        "Error Download Material Master Import Templete File"
      );
    }
  };

  const createMaterialMaster = async () => {
    if (!dubleClick) return;

    setDubleClick(false);

    let result = {
      success: "",
      message: "",
      fn: "",
    };

    try {
      const fileForm = new FormData();

      fileForm.append("file", file);

      const requestCreateMaterialMaster = await userPermAxios.post(
        "/excel_import/material_master",
        fileForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message, data } = requestCreateMaterialMaster;

      if (success) {
        result.success = "success";
        result.message = message;
        onClose();
      } else {
        if (data && data.length > 0) {
          result.success = "warning";
          result.message = message;
          downloadBase64File(data, "material_master_import_result.xlsx");
        }
      }
    } catch (err) {
      result.success = "warning";
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.success, result.message);
    }
  };

  const button_disabled = useMemo(() => {
    if (!file) {
      return true;
    }

    return false;
  }, [file]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="excel-import-modal-total-body">
        <div className="excel-import-modal-header-box">
          <div className="excel-import-modal-header-title">
            Material Master Excel Import
          </div>
          <IconButton
            onClick={onClose}
            className="excel-import-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
        <div className="excel-import-modal-main-box">
          <IconButton
            className="excel-import-templete-download-btn"
            onClick={downloadTempleteFile}
          >
            <img src="/icons/excel_icon.png" alt="excel icon" /> Template File
            Download
          </IconButton>

          {!file ? (
            <FileUploader
              classes="excel-import-drag-drop-box"
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={["xlsx"]}
              styles={{
                dropArea: {
                  border: "2px dashed #4a90e2",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "#red",
                },
                dropAreaActive: {
                  borderColor: "#6c757d",
                },
                inputLabel: {
                  color: "#000",
                },
              }}
            />
          ) : (
            <div className="excel-import-info-box">
              <div className="excel-import-info-name">{file && file?.name}</div>
              <IconButton
                className="excel-import-info-btn"
                onClick={() => {
                  setFile(null);
                }}
              >
                <CloseOutlined />
              </IconButton>
            </div>
          )}
        </div>
        <div className="excel-import-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={onClose}
            className="excel-import-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={createMaterialMaster}
            className="excel-import-modal-update-btn"
            disabled={button_disabled}
          >
            Import
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
