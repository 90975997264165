import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { AnnualIncomeAddModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import ModalBody from "../ModalBody";
import DayPicker from "../DatePicker";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const AnnualIncomeModal = () => {
  const { open, onClose, title, user_id, query_key } = useRecoilValue(
    AnnualIncomeAddModalStatus
  );

  const [reflectionDate, setReflectionDate] = useState(dayjs());
  const [annualIncomeAmount, setAnnualIncomeAmount] = useState(0);
  const [dubleClick, setDubleClick] = useState(true);

  const [refresh] = useRefreshReactQuery(query_key);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*\.?\d*$/;

    if (value === "") {
      setValue(0);
      return;
    }

    if (isValidNumber.test(value)) {
      setValue(value);
    }
  };

  const requestCreateAnnualIncome = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestUpdateApprovalStatus = await userPermAxios.post(
        `/payroll/create_annual_income`,
        {
          user_id,
          reflectionDate: reflectionDate.format("YYYY-MM-DD"),
          annualIncomeAmount: Number(annualIncomeAmount),
        }
      );

      const { success, message } = requestUpdateApprovalStatus;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmCreateAnnualIncome = () => {
    if (!dubleClick) {
      return;
    }

    const msg = "Are you sure you want to create new annual income?";

    return create_alert_fn(
      "info",
      msg,
      () => requestCreateAnnualIncome(),
      true
    );
  };

  return (
    <ModalBody open={open} onClose={onClose} title={title}>
      <div
        className="work-policy-modal-contents-body"
        style={{ gap: 20, width: 500 }}
      >
        <div
          className="work-policy-modal-swicth-btn-box"
          style={{ height: 44 }}
        >
          <div className="work-policy-modal-detail-contents-title">
            Reflection Date
          </div>
          <DayPicker
            width={180}
            value={reflectionDate}
            onChange={(e) => setReflectionDate(e)}
          />
        </div>
        <div
          className="work-policy-modal-swicth-btn-box"
          style={{ height: 44 }}
        >
          <div className="work-policy-modal-detail-contents-title">Salary</div>
          <NumberInput
            value={annualIncomeAmount}
            setValue={setAnnualIncomeAmount}
            onChange={handleNumberInputValue}
          />
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => confirmCreateAnnualIncome()}
          className="work-policy-modal-update-btn"
          disabled={annualIncomeAmount <= 0}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default AnnualIncomeModal;

const NumberInput = ({
  value,
  setValue,
  onChange = () => {},
  disabled = false,
}) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{ width: 180, boxSizing: "border-box" }}
    />
  );
};
