import React from "react";

export const HRBigCalendarHeader = ({ label }) => {
  return (
    <div className="big-calendar-header-body">
      <div className="big-calendar-header-label">{label}</div>
    </div>
  );
};

export const HRBigCalendarDateCellHeader = (
  props,
  targetDate,
  day_off_weekday_list = [],
  holidayList = []
) => {
  const { label, date } = props;

  const targetMonth = targetDate.getMonth();
  const month = date.getMonth();
  const day = date.getDay();

  let legalHoliday = "";
  let anotherMonth = "";

  if (targetMonth !== month) {
    anotherMonth = "another-month";
  }

  if (holidayList.includes(date) || day_off_weekday_list.includes(day)) {
    legalHoliday = "legal-holiday";
  }

  return (
    <div className="big-calendar-date-header-body">
      <div
        className={`big-calendar-date-header-label ${legalHoliday} ${anotherMonth}`}
      >
        {label}
      </div>
    </div>
  );
};
