import React from "react";
import { useQuery } from "react-query";

const BudgetSheetPage = () => {
  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Budget Sheet</div>
        <div className="work-policy-setting-page-btn-box"></div>
      </div>
      <div className="company-setting-contents-body"></div>
    </div>
  );
};

export default BudgetSheetPage;
