import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import IconInput from "./IconInput";

const SelectDataTable = ({
  title = "",
  rows = [],
  columns = [],
  selectModel = [],
  setSelectModel,
  table_height = 200,
  isRowSelectableField,
  searchUse = false,
  searchValue = "",
  searchOnchange = () => {},
  searchPlaceholder = "",
  searchLeftIcon,
  searchRightIcon,
  titleUse = true,
}) => {
  const [tableToggle, setTableToggle] = useState(true);

  const handleSelectionChange = (selection) => {
    setSelectModel(selection);
  };

  const tableHeight = table_height - 56 - 36 - 20;

  return (
    <div className="select-data-table-total-body">
      {titleUse && (
        <div className="select-data-table-title-total-box">
          <div className="select-data-table-title-box">
            <Box
              tabIndex={-1}
              // onClick={() => setTableToggle((prev) => !prev)}
            >
              <ExpandMoreIcon
                sx={{
                  transform: `rotateZ(${tableToggle ? 360 : 180}deg)`,
                  transition: (theme) =>
                    theme.transitions.create("transform", {
                      duration: theme.transitions.duration.shortest,
                    }),
                  width: "24px",
                  height: "24px",
                }}
              />
            </Box>

            <div className="select-data-table-title">{title}</div>
          </div>
          {searchUse && (
            <div className="select-data-table-search-box">
              <IconInput
                value={searchValue}
                onChange={searchOnchange}
                placeholder={searchPlaceholder}
                leftIcon={searchLeftIcon}
                rightIcon={searchRightIcon}
              />
            </div>
          )}
        </div>
      )}
      <div className="select-data-table-table-total-box">
        <div
          className="select-data-table-table-box"
          style={{ height: tableHeight }}
        >
          <DataGridPro
            rows={rows}
            columns={columns}
            checkboxSelection={true}
            rowSelectionModel={selectModel}
            onRowSelectionModelChange={handleSelectionChange}
            rowHeight={28}
            columnHeaderHeight={28}
            getRowClassName={(params) => {
              const { row = {} } = params;

              const { material_use } = row;

              if (material_use === 1) {
                return "already-selection-row";
              }
              return "";
            }}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
              "& .already-selection-row": {
                backgroundColor: "#F9FAFB",
                color: "#D0D5DD",
              },
            }}
            isRowSelectable={(params) => {
              if (isRowSelectableField === undefined) {
                return true;
              }

              const row_status = params?.row?.[isRowSelectableField];

              if (row_status === 1) {
                return false;
              } else {
                return true;
              }
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
            localeText={{
              noRowsLabel: !searchUse
                ? "No rows"
                : "Please search for the materials you need.",
            }}
          />
        </div>
        <div className="select-data-table-select-count-box">
          <div className="select-data-table-select-count-title">
            Total Sum:{" "}
          </div>
          <div className="select-data-table-select-count">
            {selectModel.length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDataTable;
